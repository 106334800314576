import { ImageType } from "@/components/media/media.types"
import { IntegrationOrderPropvision } from "@/integrations/types/integration-propvision"

export function createPropvisionOrderInformation(
  orders: IntegrationOrderPropvision[],
  images: ImageType[],
  resourceId: number,
  resourceType: string
): { placeholderImages: any[]; runningVideoOrders: any[] } {
  const returnValue = {
    placeholderImages: [] as ImageType[],
    runningVideoOrders: [] as IntegrationOrderPropvision[],
  }

  if (!orders?.length) {
    return returnValue
  }

  // get all running propvision orders
  const runningOrders = orders
    .filter(entry => entry?.status === "running")
    .filter(Boolean) as IntegrationOrderPropvision[]

  // for each order, create an image placeholder or count the videos
  runningOrders.forEach(order => {
    if (!order?.context?.request?.operation) return
    const context = order.context
    const operation = context.request.operation

    if (["reel"].includes(operation)) {
      // we need to check if the video belongs to this resource
      const res = getResourceAndTypeFromWebhookUrl(context.request.webhookUrl)
      if (!res || res.resourceId != resourceId || res.resourceType != resourceType) return
      returnValue.runningVideoOrders.push(order)
    } else if (["image_enhancement", "virtual_staging"].includes(operation)) {
      // for all images in the context, create a placeholder image
      context.request?.images?.forEach(ctxImage => {
        const originalImage = images.find(i => i.token === ctxImage.imageId) // comparing token to imageId here, because we use the token in the webhook (unfortunate naming)
        if (!originalImage) return
        returnValue.placeholderImages.push(createPlaceholderImage(originalImage, operation))
      })
    }
  })

  return returnValue
}

export function getRelatedAIImages(images, image) {
  if (!image) return []

  // If this image has no AI relationship, return empty array
  if (!image.ai_original_image_id && !images.some(img => img.ai_original_image_id === image.id)) {
    return []
  }

  // Return:
  // 1. The original image (if this is a derivative)
  // 2. All derivatives of this image (if this is an original)
  // 3. All "sibling" derivatives (other images derived from the same original)
  return images.filter(
    img =>
      img.id === image.ai_original_image_id ||
      img.ai_original_image_id === image.id ||
      (image.ai_original_image_id && img.ai_original_image_id === image.ai_original_image_id && img.id !== image.id)
  )
}

export function getOriginalAIImage(images, image) {
  if (!image.ai_original_image_id) return null
  return images.find(img => img.id === image.ai_original_image_id)
}

// 1. if an image has no order, but has an ai_operation and an orginal image id, it should be placed immediately after the original image
// 2. if the image has an order, it should be placed at the correct position
export function sortImagesByPositionAndRelationship(images) {
  if (!images || !images.length) return []

  const originalToGenerated = {}
  const originalImages = []

  images.forEach(img => {
    if (img.aiOriginalImageId) {
      if (!originalToGenerated[img.aiOriginalImageId]) {
        originalToGenerated[img.aiOriginalImageId] = []
      }
      originalToGenerated[img.aiOriginalImageId].push(img)
    } else {
      originalImages.push(img)
    }
  })

  originalImages.sort((a, b) => {
    const posA = a.position ?? Number.MAX_SAFE_INTEGER
    const posB = b.position ?? Number.MAX_SAFE_INTEGER
    return posA - posB
  })

  const result = []

  originalImages.forEach(originalImg => {
    result.push(originalImg)

    if (originalToGenerated[originalImg.id]) {
      const generatedImages = originalToGenerated[originalImg.id].sort((a, b) =>
        (a.aiOperation || "").localeCompare(b.aiOperation || "")
      )

      result.push(...generatedImages)
    }
  })

  return result
}

export function createPlaceholderImage(originalImage, operation) {
  return {
    ...originalImage,
    token: undefined,
    title_de: "...",
    title_en: "...",
    title_es: "...",
    title_hu: "...",
    aiOriginalImageId: originalImage.id,
    id: `placeholder_${originalImage.id}`,
    __isPending: true,
    aiOperation: operation,
    isNotForExposee: true, // hide from expose
    private: true, // hide from portal
  } as ImageTypeEnhanced
}

export function getResourceAndTypeFromWebhookUrl(webhookUrl) {
  if (!webhookUrl) return
  const url = new URL(webhookUrl)
  const resourceType = url.searchParams.get("resource_type")
  const rawResourceId = url.searchParams.get("resource_id")
  if (!rawResourceId || !resourceType) return

  const resourceId = parseInt(rawResourceId)
  if (Number.isNaN(resourceId)) return

  return { resourceType, resourceId }
}

<script setup lang="ts">
const props = defineProps<{
  borderSize?: number
  borderRadius?: number
  background?: string
  disabled?: boolean
}>()

// Set default values
const borderSize = props.borderSize ?? 1
const borderRadius = props.borderRadius ?? 5
const background = props.background ?? "linear-gradient(to top right, #6a0dad 0%, #ff69b4 100%)"
</script>

<template>
  <button
    :disabled="props.disabled"
    class="gradient-border-button"
    :style="{
      '--border-size': `${borderSize}px`,
      '--border-radius': `${borderRadius}px`,
      '--inner-radius': `${borderRadius - 1}px`,
      '--background': background,
    }"
  >
    <span class="font-regular text-sm"><slot>Button Text</slot></span>
  </button>
</template>

<style scoped>
.gradient-border-button {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 5px 16px;
  border: none;
  border-radius: var(--border-radius); /* Use the CSS variable */
  background: var(--background);
  color: #333;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
}

/* Inner element to create border effect */
.gradient-border-button::after {
  content: "";
  position: absolute;
  top: var(--border-size);
  left: var(--border-size);
  right: var(--border-size);
  bottom: var(--border-size);
  background-color: #ffffff;
  border-radius: var(--inner-radius); /* Slightly smaller to fit inside */
  z-index: 0;
  transition: opacity 0.3s ease;
}

/* Keep the text above the pseudo-element */
.gradient-border-button > * {
  position: relative;
  z-index: 1;
}

/* Hover state - make the white inner background transparent */
.gradient-border-button:hover::after {
  opacity: 0;
}

/* Change text color on hover for better contrast */
.gradient-border-button:hover {
  color: white;
}
</style>

<template>
  <form-dialog
    :title="t(`integrations.extension-propvision.${operation}.dialogTitle`)"
    submit-button-title="Generieren"
    @update:visible="emit('update:visible', $event ? operation : null)"
    @close="emit('close')"
    @complete="emit('complete', $event)"
    :submitFunc="submitFunc"
    width="600px"
    :handleError="false"
    append-to-body
    footer-type="bigButton"
  >
    <form-section>
      <span>
        {{
          t(`integrations.extension-propvision.generic.estimatedDuration`, {
            value: t(`integrations.extension-propvision.${operation}.estimatedDuration`),
          })
        }}
      </span>
      <form-row
        direction="column"
        :title="t('integrations.extension-propvision.virtual_staging.config.roomTypes.title')"
        class="mt-4"
      >
        <nice-select v-model="config.room_type" :options="translatedStageOptions.roomTypes" />
      </form-row>
      <form-row
        direction="column"
        :title="t('integrations.extension-propvision.virtual_staging.config.designStyles.title')"
        class="mt-4"
      >
        <nice-select v-model="config.design_style" :options="translatedStageOptions.designStyles" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, unref } from "vue"
import { stageOptions } from "../propvision.constants"
import { ImageTypeEnhanced } from "../propvision.types"
import { useI18n } from "vue-i18n"
import { SubmitOperationParams, SubmitOperationResultParams } from "../PropvisionImageOperations.vue"

const { t } = useI18n()
const { images, operation, onSubmit } = defineProps<{
  images: ImageTypeEnhanced[]
  operation: "virtual_staging"
  onSubmit: (params: SubmitOperationParams) => Promise<SubmitOperationResultParams>
}>()

const emit = defineEmits(["update:visible", "submit", "close", "complete"])

const translatedStageOptions = {
  roomTypes: stageOptions.roomTypes
    .map(({ id }) => ({
      id,
      name: t(`integrations.extension-propvision.virtual_staging.config.roomTypes.values.${id}`),
    }))
    .sort((a, b) => a.name.localeCompare(b.name)),
  designStyles: stageOptions.designStyles
    .map(({ id }) => ({
      id,
      name: t(`integrations.extension-propvision.virtual_staging.config.designStyles.values.${id}`),
    }))
    .sort((a, b) => a.name.localeCompare(b.name)),
}

const defaults = {
  room_type: "living",
  design_style: "modern",
}

const config = ref({ ...defaults })

const submitFunc = async () => {
  return onSubmit({ operation, images, config: unref(config.value) })
}
</script>
